import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { primaryColors, secondaryColors, statusColors, tertiaryColors } from '../../../styles/colors'
import { beautifyString, capitalizeFirstLetter } from '../../../utils/helpers'
import LineChart from '../../Charts/LineChart';
import BarChart from '../../Charts/BarChart';

function createData(
  id: number,
  keyword: string,
  monthlySearchVolumes: any[]
): any {
  return {
    id,
    keyword,
    monthlySearchVolumes
  };
}

export type ChartsProps = {
  keywordPlan: any[]
  labels: string[]
  loading: boolean
}

const Charts = (props: ChartsProps) => {
  let { labels, keywordPlan, loading } = props
  const [currentData, setCurrentData] = useState<any[]>([])

  React.useEffect(() => {
    setCurrentData(
      keywordPlan?.map((i, index) =>
        createData(
          index,
          i.keyword,
          i.monthlySearchVolumes?.map((i:any) => ({ 
            x: `${i.yearMonth.split("-")[1].substring(0,3)} ${i.yearMonth.split("-")[0]}`, 
            y: i.monthlySearches, 
            share: i.share, 
            total: i.totalMonthlySearches
          }))
        )
      )
    );

  }, [keywordPlan, loading]);
  
  const [chartType, setChartType] = useState('line')

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: "Keywords",
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems: any) => {
            return `${tooltipItems[0].label} (total: ${tooltipItems[0].raw.total})`;
          },
          label: (context: any) => {
            const { dataset, raw } = context;
            return ` ${beautifyString(dataset.label)}: ${raw.y} (${raw.share} SoS of ${raw.total})`;
          },
          afterBody: (tooltipItems: any) => {
            const xLabel = tooltipItems[0].label;
            const currentLabels = tooltipItems.map((i:any)=> i.dataset.label);
            const chart = tooltipItems[0].chart;
      
            return chart.data.datasets.filter((d:any) => !currentLabels.includes(d.label))
              .map((dataset: any) => {
                const dataIndex = chart.data.labels.indexOf(xLabel);
                const raw = dataset.data[dataIndex];
                const colorBadge = `◼`;
                return `${colorBadge} ${beautifyString(dataset.label)}: ${raw.y} (${raw.share} SoS of ${raw.total})`;
              }).join("\n");
          }
        },
      }
    },
  };
  
  const colors = [
    tertiaryColors[300],
    primaryColors[300],
    secondaryColors[300],
    tertiaryColors[600],
    primaryColors[600],
    secondaryColors[500],
    tertiaryColors[800],
    primaryColors[800],
    secondaryColors[900],
    statusColors.green,
    statusColors.red,
    statusColors.yellow,
  ];

  const data = {
    labels: labels?.map(i => (`${i.split("-")[1].substring(0,3)} ${i.split("-")[0]}`)),
    datasets: currentData.map((i, index) => {
      const color = colors[index % colors.length];
      return {
          label: i.keyword,
          data: i.monthlySearchVolumes,
          borderColor: color,
          backgroundColor: color
      }
    })
  };

  const handleChartTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setChartType(value)
  }

  return (
    <div>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Chart type</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={chartType}
            onChange={handleChartTypeChange}
          >
            <FormControlLabel value="line" control={<Radio />} label="Line" />
            <FormControlLabel value="bar" control={<Radio />} label="Bar" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {!loading && <Grid item xs={12} sx={{mt: 2, mb: 2}}>
        {chartType === "line" && <LineChart options={options} data={data} />}
        {chartType === "bar" && <BarChart options={options} data={data} />}
      </Grid>}
      {loading && <Grid container xs={12} sx={{mt: 2, mb: 2, minHeight: 600, alignItems: 'center', justifyContent:'center'}}>
        <CircularProgress style={{ width: '36px', height: '36px', color: '#000' }}/>
      </Grid>}
    </div>
  )
}

export default Charts




