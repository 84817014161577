import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { desktop_h3, desktop_h4 } from '../styles/textStyles';

export type AccordionContainerProps = {
  title: string,
  children: React.ReactNode
  styles: Object
  titleStyles?: Object
  titleElement?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  isExpanded?: boolean
}
const AccordionContainer = ({title, children, styles, titleStyles, titleElement, isExpanded}: AccordionContainerProps) => {

  const [expanded, setExpanded] = useState(isExpanded || false)
  return (
    <Accordion sx={styles} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={titleStyles ?? {}} variant={titleElement ?? 'h6'}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionContainer