import { convertObjectToArray } from './../../utils/helpers';
import {
  AdSetBillingEvent,
  BudgetType,
  AdAutomationSettings,
  MarketingObjStatus,
  CampaignObjectives,
  MetaAction,
  MetaState,
  SET_AD_AUTOMATION_SETTINGS,
  GET_AD_AUTOMATION_SETTINGS,
  SET_AD_AUTOMATIONS,
  SET_AD_AUTOMATION_SETTINGS_ERRORS,
  GET_ALL_POSTS,
  SET_ALL_POSTS,
  GET_MATCHED_POSTS,
  SET_MATCHED_POSTS,
  SET_ADVANCED_POST_BOOSTING,
  AdvancedPostBoosting,
  SET_POST_BOOSTING_RULES,
  SET_ADVANCED_POST_BOOSTING_ERRORS,
  EntireAdAutomation,
  Campaign,
  AdSet,
  EngagementOptimizationGoals,
  SET_AD_SET,
  SET_CAMPAIGN,
  TrafficConversions,
  TrafficOptimizationGoals,
  EngagementConversions,
  EngagementWebsiteConversionOptimizationGoals,
  BidStrategy,
  AdPromotedObjectCustomEventType,
  Ad,
  CallToActionButtonType,
  MarketingSettings,
  SET_POSTS_START_DATE,
  SET_POSTS_END_DATE,
  SET_META_ASSETS,
  MetaAssets,
  BudgetLevel,
  BudgetProps,
} from "./MetaTypes";


export const initialBudgetProps: BudgetProps = {
  budgetLevel: BudgetLevel.AD_SET_LEVEL,
  budget_type: BudgetType.daily,
  daily_budget: 0,
  lifetime_budget: 0,
  budget: 1,
  bid_strategy: BidStrategy.LOWEST_COST_WITHOUT_CAP,
  bid_amount: 0.1
}

export const initialCampaign: Campaign = {
  id: "",
  name: "",
  objective: CampaignObjectives.AWARENESS,
  status: MarketingObjStatus.ACTIVE,
  special_ad_categories: [],
  // budget fields - start
  budget_type: initialBudgetProps.budget_type,
  daily_budget: initialBudgetProps.daily_budget,
  lifetime_budget: initialBudgetProps.lifetime_budget,
  budget: initialBudgetProps.budget,
  bid_strategy: BidStrategy.LOWEST_COST_WITHOUT_CAP,
  // budget fields - end
};

export const initialAdSet: AdSet = {
  id: "",
  name: "",
  campaign_id: "",
  // budget fields - start
  budget_type: initialBudgetProps.budget_type,
  daily_budget: initialBudgetProps.daily_budget,
  lifetime_budget: initialBudgetProps.lifetime_budget,
  budget: initialBudgetProps.budget,
  bid_strategy: BidStrategy.LOWEST_COST_WITHOUT_CAP,
  bid_amount: 0.1,
  // budget fields - end
  status: MarketingObjStatus.ACTIVE,
  start_time: "",
  end_time: "",
  dynamicDays: 1,
  defaultCityRadius: 40,
  dynamicPeriodOfTime: true,
  conversion: EngagementConversions.WEBSITE,
  optimization_goal: EngagementWebsiteConversionOptimizationGoals.IMPRESSIONS,
  billing_event: AdSetBillingEvent.IMPRESSIONS,
  promoted_object: {
    //page_id: '',
    pixel_id: "",
    custom_event_type: AdPromotedObjectCustomEventType.OTHER,
  },
  targeting: {
    age_min: 18,
    age_max: 40,
    genders: [1, 2],
    geo_locations: {
      countries: ["FI"],
      cities: [],
    },
    device_platforms: [],
    publisher_platforms: [],
    facebook_positions: [],
    audience_network_positions: [],
    instagram_positions: [],
    messenger_positions: [],
    // helper field
    placementsSelection: "automatically",
  },
  // helper fields
  audience: "manually",
  currentSavedAudienceId: '',
  currentSavedAudience: undefined
};

export const initialAdAutomationSettings: AdAutomationSettings = {
  name: "",
  active: true,
  budgetProps: initialBudgetProps,
  campaign: initialCampaign,
  adSet: initialAdSet,
  ad: {
    urlParameters: "utm_source=facebook&utm_medium=cpc"
  },
  keywords: [],
  excludedKeywords: [],
  maxNumberOfActiveAds: 200
};

export const initialFullAdAutomation: EntireAdAutomation = {
  id: "",
  name: "",
  active: true,
  campaign: initialCampaign,
  adSet: initialAdSet,
  keywords: [],
  excludedKeywords: [],
  ads: [],
  adCreatives: [],
  createdAt: "",
};

export const initialAd: Ad = {
  urlParameters: "utm_source=facebook&utm_medium=cpc",
  addCTA: false,
  callToActionButtonType: CallToActionButtonType.LIKE_PAGE,
  url: ""
};

export const initialAdvancedPostBoosting: AdvancedPostBoosting = {
  name: "",
  filters: {
    types: ["video", "photo", "link", "post", "event", "other"],
    conditions: [],
    logicalOperator: "and",
  },
  newOrExistingCampaign: "existing-campaign",
  budgetProps: initialBudgetProps,
  campaign: initialCampaign,
  adSet: initialAdSet,
  ad: initialAd,
};

const initialMarketingSettings: MarketingSettings = {
  currentConversionLocations: convertObjectToArray(EngagementConversions),
  currentOptimizationGoals: convertObjectToArray(EngagementOptimizationGoals),
  currentBillingEvents: convertObjectToArray(AdSetBillingEvent.IMPRESSIONS)
}

export const initialMetaAssets: MetaAssets = {
  userAdAccounts: [],
  userFacebookPages: [],
  currentAssets: {
    adAccountId: '',
    adAccountName: '',
    facebookPageId: '',
    facebookPageName: ''
  },
  savedAssetsIntoDB: {
    adAccountId: '',
    adAccountName: '',
    facebookPageId: '',
    facebookPageName: ''
  },
  openMetaAssetsDialog: false
}

export const initialMetaState: MetaState = {
  marketingSettings: initialMarketingSettings,
  campaign: initialCampaign,
  campaignErrors: [],
  adSet: initialAdSet,
  adSetErrors: [],
  adAutomationSettings: initialAdAutomationSettings,
  adAutomationSettingsErrors: [],
  adAutomations: [],
  allPosts: [],
  matchedPosts: [],
  postsStartDate: new Date(Date.now() - (30 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
  postsEndDate: new Date().toISOString().split('T')[0],
  advancedPostBoosting: initialAdvancedPostBoosting,
  advancedPostBoostingErrors: [],
  postBoostingRules: [],
  metaAssets: initialMetaAssets
};

const MetaReducer = (state: MetaState, action: MetaAction): MetaState => {
  switch (action.type) {
    case GET_AD_AUTOMATION_SETTINGS:
      return state;
    case SET_AD_AUTOMATION_SETTINGS:
      return {
        ...state,
        adAutomationSettings: action.data,
      };
    case SET_AD_AUTOMATIONS:
      return {
        ...state,
        adAutomations: action.data,
      };
    case SET_AD_AUTOMATION_SETTINGS_ERRORS:
      return {
        ...state,
        adAutomationSettingsErrors: action.data,
      };
    case GET_ALL_POSTS:
      return {
        ...state,
        allPosts: action.data,
      };
    case SET_ALL_POSTS:
      return {
        ...state,
        allPosts: action.data,
      };
    case SET_MATCHED_POSTS:
      return {
        ...state,
        matchedPosts: action.data,
      };
    case SET_ADVANCED_POST_BOOSTING:
      return {
        ...state,
        advancedPostBoosting: action.data,
      };
    case SET_POST_BOOSTING_RULES:
      return {
        ...state,
        postBoostingRules: action.data,
      };
    case SET_ADVANCED_POST_BOOSTING_ERRORS:
      return {
        ...state,
        advancedPostBoostingErrors: action.data,
      };
    case SET_CAMPAIGN:
      return {
        ...state,
        campaign: action.data,
      };
    case SET_AD_SET:
      return {
        ...state,
        adSet: action.data,
      };
    case SET_POSTS_START_DATE:
      return {
        ...state,
        postsStartDate: action.data,
      };
    case SET_POSTS_END_DATE:
      return {
        ...state,
        postsEndDate: action.data,
      };
    case SET_META_ASSETS:
      return {
        ...state,
        metaAssets: action.data,
      };
    default:
      return state;
  }
};
export default MetaReducer;
