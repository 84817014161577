import axios from "axios";
import { apiRootUrl } from "./Types";
import { Campaign, AdSet, AdAutomationSettings, AdSetTargeting, AdSetAudienceInfo, EngagementOptimizationGoals, TrafficOptimizationGoals, AwarenessOptimizationGoals } from "../context/meta/MetaTypes";
import { getApi } from "./Utils";

axios.defaults.withCredentials = true;

export const createNewCampaign = async (customerId: number, campaign: Campaign) => {
  try {
    const res = await axios.post(`${apiRootUrl}/meta/marketing/customer/${customerId}/campaign`, campaign);
    return res.data;
  } catch (err: any) {
    if (err.response.data.body) {
      //const errors = err.response.data.body.map((err:any) => `${err.dataPath} : ${err.message}`)
      return {
        type: "json schema validation error",
        error: "Please makesure that all fields are filled correctly.",
      };
    }
    const mes = err.response.data.error.message;
    const errorUserTitle = err.response.data.error.error_user_title;
    const errorUserMsg = err.response.data.error.error_user_msg;
    const str = `${mes ? mes : ""} ${errorUserTitle ? errorUserTitle : ""} ${errorUserMsg ? errorUserMsg : ""}`;
    return {
      type: "Facebook server error",
      error: str,
    };
  }
};

export const createNewAdSet = async (customerId: number, adSet: AdSet) => {
  try {
    const res = await axios.post(`${apiRootUrl}/meta/marketing/customer/${customerId}/adset`, adSet);
    return res.data;
  } catch (err: any) {
    if (err.response.data.body) {
      return {
        type: "json schema validation error",
        error: "Please insure that all fields are filled correctly.",
      };
    }
    const mes = err.response.data.error.message;
    const errorUserTitle = err.response.data.error.error_user_title;
    const errorUserMsg = err.response.data.error.error_user_msg;
    const str = `${mes ? mes : ""} ${errorUserTitle ? errorUserTitle : ""} ${errorUserMsg ? errorUserMsg : ""}`;
    return {
      type: "Facebook server error",
      error: str,
    };
  }
};

// Deletes ad automation by id including its campaign and ad set.
export const deleteAdAutomation = async (customerId: number, adAutomationId: string) => {
  try {
    const res = await axios.delete(`${apiRootUrl}/meta/marketing/customer/${customerId}/adautomations/${adAutomationId}`);
    return res.status;
  } catch (err: any) {
    console.error(err.response.data);
  }
};

// Get ad automations
export const getAdAutomations = async (customerId: number) => {
  try {
    const res = await axios.get(`${apiRootUrl}/meta/marketing/customer/${customerId}/adautomations`);
    return res.data;
  } catch (err: any) {
    console.error("adautomations error");
    console.error(err);
    console.error(err.response.data);
  }
};

// Get full ad automation by id
export const getFullAdAutomationById = async (customerId: number, adAutomationId: string) => {
  try {
    const res = await axios.get(`${apiRootUrl}/meta/marketing/customer/${customerId}/adautomations/${adAutomationId}`);
    return res.data;
  } catch (err: any) {
    console.error("adautomations error");
    console.error(err);
    console.error(err.response.data);
  }
};

// Get All campaigns including their ad sets
// This function reuturns an object which contains two arrays { allCampaignsIncludingAdSets[], campaignsByKiva[] }
export const getAllCampaigns = async (customerId: number) => {
  try {
    const res = await axios.get(`${apiRootUrl}/meta/marketing/customer/${customerId}/campaigns`);
    return res.data;
  } catch (err: any) {
    console.error(err.response.data);
  }
};

export const getCampaignAdSets = async (customerId: number, campaignId: string) => {
  try {
    const res = await axios.get(`${apiRootUrl}/meta/marketing/customer/${customerId}/campaigns/${campaignId}/adsets`);
    return res.data;
  } catch (err: any) {
    console.error(err.response.data);
  }
};

// Creates a new ad for every post
export const createNewAds = async (customerId: number, posts: any[], adSetId: string) => {
  try {
    const res = await axios.post(`${apiRootUrl}/meta/marketing/customer/${customerId}/ad`, { posts, adSetId });
    return res.data;
  } catch (err: any) {
    return err.response.data;
  }
};

/**
 * Deletes ad crative or ad by id.
 * For example, the user is tring to create ads for multiple posts, but one of the ad creatvies or the ads cannot be created for some reason.
 * @param customerId
 * @param objectId the ad or ad creative id
 * @returns 204 if the deletion were successful, otherwise the error.
 */
export const deleteMarkeitngObject = async (customerId: number, objectId: string) => {
  try {
    const res = await axios.delete(`${apiRootUrl}/meta/marketing/customer/${customerId}/object/${objectId}`);
    return res.status;
  } catch (err: any) {
    return err.response.data.error;
  }
};

export const createNewAdAutomation = async (customerId: number, adAutomation: AdAutomationSettings) => {
  try {
    const res = await axios.post(`${apiRootUrl}/meta/marketing/customer/${customerId}/adautomations`, adAutomation);
    return res.data;
  } catch (err: any) {
    if (err.response.data.body) {
      //const errors = err.response.data.body.map((err:any) => `${err.dataPath} : ${err.message}`)
      return {
        type: "json schema validation error",
        error: "Please makesure that all fields are filled correctly.",
      };
    } else {
      return {
        type: "Facebook server error",
        error: err.response.data.message,
      };
    }
  }
};

export const updateAdAutomation = async (customerId: number, adAutomationId: string, data: any) => {
  try {
    const res = await axios.put(`${apiRootUrl}/meta/marketing/customer/${customerId}/adautomations/${adAutomationId}`, data);
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const updateAdAutomationTargeting = async (customerId: number, adAutomationId: string, audienceInfo: AdSetAudienceInfo, targeting: AdSetTargeting) => {

  return await axios.put(`${apiRootUrl}/meta/marketing/customer/${customerId}/adautomations/${adAutomationId}/targeting`, { audienceInfo, targeting });
};

export const updateAdAutomationOptimizationGoal = async (customerId: number, adAutomationId: string, data: { optimization_goal: EngagementOptimizationGoals | TrafficOptimizationGoals | AwarenessOptimizationGoals }) => {

  return await axios.put(`${apiRootUrl}/meta/marketing/customer/${customerId}/adautomations/${adAutomationId}/adset/optimizationGoal`, data);
};

export const updateAdAutomationDefaultCityRadius = async (customerId: number, adAutomationId: string, radius: number) => {
  await axios.put(`${apiRootUrl}/meta/marketing/customer/${customerId}/adautomations/${adAutomationId}/defaultCityRadius/${radius}`, {});
};

export const updateAdAutomationKeywords = async (customerId: number, adAutomationId: string, keywords: string[]) => {
  await axios.put(`${apiRootUrl}/meta/marketing/customer/${customerId}/adautomations/${adAutomationId}/keywords/${encodeURIComponent(keywords.join(","))}`, {});
};

export const updateAdAutomationExcludedKeywords = async (customerId: number, adAutomationId: string, excludedKeywords: string[]) => {
  await axios.put(`${apiRootUrl}/meta/marketing/customer/${customerId}/adautomations/${adAutomationId}/excludedKeywords/${encodeURIComponent(excludedKeywords.join(",")) || "null" }`, {});
};

export const updateAdAutomationBudget = async (customerId: number, adAutomationId: string, data: { isMonthlyBudget: boolean, budget: number }) => {
  return await axios.put(`${apiRootUrl}/meta/marketing/customer/${customerId}/adautomations/${adAutomationId}/budget`, data);
};


export const getBusinessOwnedPixels = async (customerId: number, useCache: boolean) => {
  try {
    const res = await axios.get(`${apiRootUrl}/meta/marketing/customer/${customerId}/pixels?useCache=${useCache}`);
    return res.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getSavedAudiences = async (customerId: number, useCache: boolean) => {
  try {
    const res = await axios.get(`${apiRootUrl}/meta/marketing/customer/${customerId}/saved-audiences?useCache=${useCache}`);
    return res.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getMetaAuthStatus = async (customerId: number) => {
  try {
    const res = await axios.get(`${apiRootUrl}/meta/marketing/customer/${customerId}/auth-status`);
    return res.data;
  } catch (err: any) {
    return err.response.data;
  }
};

/**
 * @desc used to get the Facebook pages & ad accounts that a user own or has access to
 * @param customerId
 * @returns
 */
export const apiGetAssetsUserHasAccessTo = async (customerId: number) => {
  try {
    const res = await axios.get(`${apiRootUrl}/meta/marketing/customer/${customerId}/user-assets`);
    return res.data;
  } catch (err: any) {
    return err.response.data
  }
};

/**
 * @desc used to save the choosen meta assets (Facebook page & ad account)
 * @param customerId
 * @param metaAssets
 * @returns
 */
export const apiSaveChosenMetaAssets = async (customerId: number, metaAssets: { facebookPageId: string, facebookPageName: string, adAccountId: string, adAccountName: string }) => {
  try {
    const res = await axios.post(`${apiRootUrl}/meta/marketing/customer/${customerId}/save-assets`, { metaAssets });
    return res.data;
  } catch (err: any) {
    return err.response.data
  }
};

/**
 * @desc used to get saved Mata assets from the db
 * @param customerId
 * @returns
 */
export const apiGetSavedMetaAssets = async (customerId: number) => {
  try {
    const res = await axios.get(`${apiRootUrl}/meta/marketing/customer/${customerId}/saved-assets`);
    return res.data;
  } catch (err: any) {
    return err.response.data
  }
};

export const apiGetLocations = async (customerId: number, countryCode: string, type: "city" | "zip", query: string): Promise<{ key: string, name: string }[]> => {
  return await getApi(`meta/marketing/customer/${customerId}/locations?countryCode=${countryCode}&type=${type}&query=${encodeURIComponent(query)}`)
}


export const getDeletedUserAssociatedWithCode = async (confirmationCode: string) => {
  try {
    const res = await axios.get(`${apiRootUrl}/meta/webhooks/deleted-users/${confirmationCode}`)
    return res.data
  } catch (error: any) {
    return error.response.data
  }
}
