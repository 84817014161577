import styled from 'styled-components';
import { useCustomerState } from '../../context/customer/CustomerContext';
import { AdTemplateManager } from '../AdTemplateView/AdTemplateManager';
import { Box, Typography } from '@mui/material';
import { desktop_h1 } from '../../styles/textStyles';
import { viewContainer } from '../../styles/layoutStyles';


export const AdTemplateView = () => {
  const customer = useCustomerState()
  return (customer.selected ? <Box sx={{...viewContainer}}>
    <Typography variant='h1' sx={{...desktop_h1, mb: '32px'}}>Template editor</Typography>
    <AdTemplateManager />
  </Box> : null)
}