import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { desktop_h1 } from '../../styles/textStyles'
import DateRangePickerMui from '../DateRangePickerMui'
import CircularProgressLoading from '../CircularProgressLoading'
import KeywordSearch from '../Ads/MoreFilters/KeywordSearch'
import GridViewItemsCountToggle from '../GridViewItemsCountToggle'
import { useCustomerState } from '../../context/customer/CustomerContext'
import { useCompetitorStateValue } from '../../context/competitors/CompetitorContext'
import { setCompetitorAdUnits, setCompetitorFacebookPages, setCompetitorSettings, setCompetitorState, setGoogleAdvertisers } from '../../context/competitors/CompetitorActions'
import { apiGetCompetitorAds } from '../../api/Competitors'
import dayjs from 'dayjs'
import { DateRange } from '@mui/x-date-pickers-pro/internals/models'
import { CompetitorCreativePreview } from './CompetitorCreativePreview'
import { CompetitorAdStatus, CompetitorCreative, CompetitorCreativeResponse, CompetitorSettings, MetaCompetitorAdType } from '../../context/competitors/CompetitorTypes'
import Channels from './Channels'
import GoogleAdvertisersFilter from './GoogleAdvertisersFilter'
import { apiGetCompetitorSettings } from '../../api/Customer'
import CompetitorFacebookPagesFilter from './CompetitorFacebookPagesFilter'
import { CustomerTree } from '../../api/Types'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { primaryColors } from '../../styles/colors'

const pageSize = 24;

const CompetitorCreatives = () => {

  const customerState = useCustomerState()
  const { selected } = customerState
  const [{ competitorAdUnits, competitorSettings }, competitorDispatch] = useCompetitorStateValue()
  const { startDate, endDate, creatives, searchKeyword, status, loading, gridViewItemsCount, facebookPageIds, adType, categories, googleAdvertiserIds, start } = competitorAdUnits

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isInitialLoading, setIsInitialLoading] = useState(false)
  const [previousSelected, setPreviousSelected] = useState<CustomerTree>()
  const [showScrollUpBtn, setShowScrollUpBtn] = useState(false);

  const emptyCreatives = {creatives: [], start: 0, total: 0}
  
  useEffect(() => {
    if(selected){
        setIsInitialLoading(true)
        apiGetCompetitorSettings(selected?.id).then((response: CompetitorSettings) => {
            const metaIds = response.meta?.facebookPages?.map(i => i.id) || []
            const googleIds = response.google?.advertisers?.map(i => i.id) || []
            competitorDispatch(setCompetitorState({
              competitorSettings: response,
              competitorAdUnits: { ...competitorAdUnits, 
                creatives: emptyCreatives,
                loading: true,
                facebookPageIds: metaIds,
                googleAdvertiserIds: googleIds 
              }
            }))
            return {metaIds, googleIds}
        }).then(({metaIds, googleIds}) => {
          apiGetCompetitorAds(selected.id, {
            searchKeyword,
            startDate,
            endDate,
            status,
            adType,
            facebookPageIds: metaIds,
            googleAdvertiserIds: googleIds,
            categories,
            start, 
            count: pageSize
          }).then((resp: CompetitorCreativeResponse) => {
            competitorDispatch(setCompetitorAdUnits({
              ...competitorAdUnits,
              creatives: resp, 
              facebookPageIds: metaIds,
              googleAdvertiserIds: googleIds,
              loading: false
            }))
          }).catch(() =>  {
            competitorDispatch(setCompetitorAdUnits({
              ...competitorAdUnits,
              creatives: creatives, loading: false
            }))
          }).finally(() => {setIsInitialLoading(false)});

        })
    }
  }, [selected]);
  
  
  const getCompetitorAds = () => {
    if (selected) {
      competitorDispatch(setCompetitorAdUnits({ ...competitorAdUnits, loading: true }))
      apiGetCompetitorAds(selected!.id, {
        searchKeyword,
        startDate: startDate, endDate: endDate, status, 
        facebookPageIds: facebookPageIds, 
        googleAdvertiserIds: googleAdvertiserIds, 
        adType,
        categories,
        start: 0,
        count: pageSize
      }).then((resp: CompetitorCreativeResponse) => {
        competitorDispatch(setCompetitorAdUnits({
          ...competitorAdUnits,
          creatives: resp,
          loading: false
        }))
      }).catch(() => {
        competitorDispatch(setCompetitorAdUnits({
          ...competitorAdUnits,
          creatives: creatives, loading: false
        }))

      })
    }
  }

  const getCompetitorAdsUsingParams = (params: any) => {
    if (selected) {
      competitorDispatch(setCompetitorAdUnits({ ...competitorAdUnits, loading: true, ...params }))
      apiGetCompetitorAds(selected.id, params).then((resp: CompetitorCreativeResponse) => {
        const updatedCreatives = params.start === 0 ? resp.creatives : [...creatives.creatives, ...resp.creatives]
        competitorDispatch(setCompetitorAdUnits({
          ...competitorAdUnits,
          creatives: {
            creatives: updatedCreatives,
            start: resp.start,
            total: resp.total
          },
          startDate: params.startDate, endDate: params.endDate, 
          searchKeyword: params.searchKeyword, status: params.status, adType: params.adType,
          facebookPageIds: params.facebookPageIds,
          googleAdvertiserIds: params.googleAdvertiserIds,
          start: params.start,
          loading: false
        }))
        if(isLoadingMore){
          setIsLoadingMore(false)
        }

      }).catch(() => {
        competitorDispatch(setCompetitorAdUnits({
          ...competitorAdUnits,
          creatives: creatives, loading: false
        }))
        if(isLoadingMore){
          setIsLoadingMore(false)
        }
      })
    }
  }


  const handleDateRangeChange = (dateRange: DateRange<dayjs.Dayjs>) => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      getCompetitorAdsUsingParams({
          searchKeyword,
          startDate: dateRange[0].endOf('day').toDate(),
          endDate: dateRange[1].endOf('day').toDate(),
          status,
          adType,
          facebookPageIds,
          googleAdvertiserIds,
          categories,
          start: 0,
          count: pageSize
      });
    }
};

  const handleKeywordSearch = (event: ChangeEvent<HTMLInputElement>) => {
    competitorDispatch(setCompetitorAdUnits({...competitorAdUnits, searchKeyword: event.target.value, start: 0}))
  }

  const handleGridViewItemsCountChange = (event: React.MouseEvent<HTMLElement>, newItems: number) => {
    competitorDispatch(setCompetitorAdUnits({ ...competitorAdUnits, gridViewItemsCount: newItems, loading: false }))
  }

  const loadMore = () => {
    setIsLoadingMore(true)
    getCompetitorAdsUsingParams({
      searchKeyword,
      startDate,
      endDate,
      status,
      adType,
      facebookPageIds,
      googleAdvertiserIds,
      categories,
      start: start + pageSize,
      count: pageSize
    });
  }

  const getScrollPosition = () => window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  const setScrollPosition = (pos:any) => window.scrollTo({ top: pos, behavior: 'smooth' });

  const handleLoadMoreWithScroll = () => {
    const scrollPosition = getScrollPosition();
    loadMore();
    setScrollPosition(scrollPosition);
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollUpBtn(window.scrollY > 200);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box sx={{ margin: '50px' }}>
      <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '36px' }}>
        <Box sx={{ width: '50%' }}>
          <Typography variant='h1' sx={desktop_h1}>Competitor Creatives </Typography>
        </Box>
        {/* Date picker */}
        <Box sx={{ alignItems: 'center', minWidth: '280px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <Box sx={{}}>
            {!loading && <Box sx={{ fontSize: '0.9em' }}>{creatives.creatives.length} of {creatives.total} ad units</Box>}
            {loading && <Box sx={{ fontSize: '0.9em' }}>
              <CircularProgressLoading boxStyles={{ display: 'flex', alignItems: 'center', justifyContent: 'center' } }
              circularProgressStyles={{ width: '24px', height: '24px', color: primaryColors[400] }} />
            </Box>}
          </Box>
          <DateRangePickerMui startDate={startDate} endDate={endDate} onChange={handleDateRangeChange} onClose={() => ''} />
        </Box>
      </Grid>

      <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', mb: '36px', mt: '36px', display: 'flex', rowGap: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
          <Channels handleAdsSearchBtnClick={() => getCompetitorAds()} />
          {['meta'].some(i => categories.includes(i)) && <CompetitorFacebookPagesFilter handleAdsSearchBtnClick={() => getCompetitorAds()}/>}
          {["YOUTUBE", "SEARCH", "PLAY", "SHOPPING", "MAPS"].some(i => categories.includes(i)) && <GoogleAdvertisersFilter handleAdsSearchBtnClick={() => getCompetitorAds()}/>}  
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>

          <Box>
            <GridViewItemsCountToggle items={gridViewItemsCount} handleChange={handleGridViewItemsCountChange} />
          </Box>
        </Box>
      </Grid>

      {true && <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '36px' }}>
        <Box sx={{ alignItems: 'center', width: '50%' }}>
          <KeywordSearch keyword={searchKeyword} onChange={handleKeywordSearch} handleAdsSearchBtnClick={() => getCompetitorAds()} />
        </Box>

        <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'flex-end', alignItems: 'center', width: '50%' }}>
         
        </Box>
      </Grid>}


      <>

        {creatives.creatives.length === 0 && !loading &&
        <>
          <Box sx={{ display: 'grid', gridTemplateColumns: `repeat(${1}, 1fr)`, gap: '32px 24px' }} > 
            <Typography>Creatives not found for {selected?.name}.</Typography>
          </Box>
        </>}

        {isInitialLoading && <CircularProgressLoading />}

        {!isInitialLoading && 
        <>
          <Box sx={{ display: 'grid', gridTemplateColumns: `repeat(${gridViewItemsCount}, 1fr)`, gap: '32px 24px' }} > 
            {creatives.creatives.filter(c => c.url).map((creative: CompetitorCreative) => <CompetitorCreativePreview key={creative.id} competitorCreative={creative}/>)}       
          </Box>
          
          <Box sx={totalContainerStyles}>
            {creatives.total > 0 && !loading && <Box sx={{ fontSize: '0.9em' }}>{creatives.creatives.length} of {creatives.total} ad units</Box>}
          </Box>
          {isLoadingMore && loading && <CircularProgressLoading />}
          <Box sx={totalContainerStyles}>
            {creatives.creatives.length < creatives.total && !loading && <Box sx={loadMoreBtnStyles} onClick={handleLoadMoreWithScroll}>Load more</Box>}
          </Box>
        </>}

      </>

      {showScrollUpBtn && <Button variant="outlined" sx={{position: 'fixed', right: 0, bottom: '50px', background: primaryColors[50]}} onClick={() => setScrollPosition(0)}>
        <KeyboardArrowUpIcon />
      </Button>}

    </Box>)

}
export default CompetitorCreatives

const totalContainerStyles = {
  marginTop: '1.0em',
  width: '100%',
  justifyContent: 'center',
  alignContent: 'center',
  display: 'flex'
}

const loadMoreBtnStyles = {
  cursor: 'pointer',
  borderRadius: '24px',
  backgroundColor: '#f2f2f2',
  textAlign: 'center',
  maxWidth: '7em',
  minWidth: '7em',
  alignSelf: 'center',
  padding: '5px',
  '&:hover': {
    backgroundColor: '#D3D3D3'
  }
}
