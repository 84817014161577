import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import { Grid, TextField, Divider } from '@mui/material';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdAutomationSettings, setAdAutomationSettingsErrors } from '../../../context/meta/MetaActions';
import { stringToArrayOfStrings, arrayOfStringsToString } from '../../../utils/helpers'
import { TextareaAutosize } from '@mui/material';

const MetaKeywords: React.FC<any> = () => {

  const [{ adAutomationSettings, adAutomationSettingsErrors }, dispatch] = useStateValue()
  const [keywords, setKeywords] = useState<string>(arrayOfStringsToString(adAutomationSettings.keywords))
  const [excludedKeywords, setExcludedKeywords] = useState<string>(arrayOfStringsToString(adAutomationSettings.excludedKeywords))

  const handleKeywordsChange = (e: any) => {
    const kw = e.target.value;
    const keyWordsError = "Keywords could not be empty. Enter at least one keyword which has at least 2 characters."
    setKeywords(kw)
    if (kw && kw.length >= 2) {
      dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== keyWordsError)))
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, keywords: stringToArrayOfStrings(kw) }))
    } else {
      dispatch(setAdAutomationSettingsErrors(adAutomationSettingsErrors.filter(e => e !== keyWordsError).concat([keyWordsError])))
    }
  }

  const handleExcludedKeywordsChange = (e: any) => {
    const exKeywords = e.target.value;
    setExcludedKeywords(exKeywords)
    if (exKeywords && exKeywords.length >= 2) {
      dispatch(setAdAutomationSettings({ ...adAutomationSettings, excludedKeywords: stringToArrayOfStrings(exKeywords) }))
    }
  }

  const handleUrlParametersChange = (e: any) => {
    dispatch(setAdAutomationSettings({ ...adAutomationSettings, ad: { ...adAutomationSettings.ad, urlParameters: e.target.value } }))
  }

  return (
    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>

      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Set keywords or hashtags</Typography>
        <Typography variant='body2'>Please, enter the keywords or hashtags separated by a comma.</Typography>
      </Grid>
      <Grid item xs={12}>

        <TextareaAutosize
          aria-label="minimum height"
          minRows={5}
          value={keywords}
          onChange={handleKeywordsChange}
          placeholder="For example: #digitalmarketing, #analytics, #technology, digital marketing, analytics, technology..."
          style={{ width: 'calc(100% - 20px)', padding: '10px' }} />
      </Grid>

      <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Set excluded keywords and hashtags (optional)</Typography>
        <Typography variant='body2'>Please, enter the excluded keywords and hashtags separated by a comma.</Typography>
      </Grid>
      <Grid item xs={12}>

        <TextareaAutosize
          aria-label="minimum height"
          minRows={5}
          value={excludedKeywords}
          onChange={handleExcludedKeywordsChange}
          placeholder="For example: #digitalmarketing, #analytics, #technology, digital marketing, analytics, technology..."
          style={{ width: 'calc(100% - 20px)', padding: '10px' }} />
      </Grid>


      <Grid item xs={12} sx={{ mt: 3, mb: 2 }}>
          <Divider />
        </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">URL parameters</Typography>
        <Typography variant="body2">Set URL parameters that will be added to every ad link.</Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="standard-basic"
          label="URL parameters"
          name='URL parameters'
          variant="outlined"
          fullWidth
          value={adAutomationSettings.ad.urlParameters}
          onChange={handleUrlParametersChange}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

    </Grid>
  )
}

export default MetaKeywords;