import moment from "moment"
import { postApi } from "./Utils"
import { CompetitorAdStatus, CompetitorCreative, CompetitorCreativeResponse, MetaCompetitorAdType } from "../context/competitors/CompetitorTypes"

export type GetCompetitorAdsParams = {
  categories: string[]
  searchKeyword: string
  startDate: Date
  endDate: Date
  status: CompetitorAdStatus
  facebookPageIds: string[]
  googleAdvertiserIds: string[]
  adType: MetaCompetitorAdType
  start: number,
  count: number
}

export const apiGetCompetitorAds = async (customerId: number, params: GetCompetitorAdsParams): Promise<CompetitorCreativeResponse> => {
  return postApi(`/competitors/creatives/customer/${customerId}`, {
    ...params,
    startDate: moment(params.startDate.toISOString()).format("YYYY-MM-DD"),
    endDate: moment(params.endDate.toISOString()).format("YYYY-MM-DD"),
  }, { creatives: [], start: 0, total: 0 })
}
